import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, Switch, TimePicker } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { t } from '../LangProvider';

const RULES = [{ required: true }];

export const FormField = {
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Text: ({ required, label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <Input placeholder={`${t(placeholder ?? label ?? '')}...`} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Number: ({ required, label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <InputNumber style={{ width: '100%' }} placeholder={`${t(placeholder ?? label ?? '')}...`} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  TextArea: ({ required, label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <Input.TextArea placeholder={`${t(placeholder ?? label ?? '')}...`} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Date: ({ required, label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <DatePicker placeholder={`${t(placeholder ?? label ?? '')}...`} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Time: ({ required, label, placeholder, timeProps, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <TimePicker placeholder={`${t(placeholder ?? label ?? '')}...`} {...timeProps} format={'HH:mm'} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Password: ({ required, label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <Input.Password placeholder={`${t(placeholder ?? label ?? '')}...`} autoComplete="new-password" />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Select: ({ dataSource = [], label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={props.required && RULES}>
        <Select
          placeholder={`${t(placeholder ?? label ?? '')}...`}
          allowClear={true}
          showSearch={true}
          optionFilterProp="label"
          options={dataSource}
        />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  MultiSelect: ({ required, dataSource = [], label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <Select mode="multiple" placeholder={`${t(placeholder ?? label ?? '')}...`} options={dataSource} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  CheckboxGroup: ({ required, dataSource = [], label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <Checkbox.Group options={dataSource} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Radio: ({ required, dataSource = [], label, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <Radio.Group optionType="button" options={dataSource} />
      </Form.Item>
    );
  },
  Submit: ({ text = 'Aceptar', loading, style }) => {
    return (
      <Button
        htmlType="submit"
        icon={<CheckOutlined />}
        style={{ width: '100%', ...style }}
        type="primary"
        loading={loading}
      >
        {t(text)}
      </Button>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Switch: ({ required, label, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES} valuePropName="checked">
        <Switch />
      </Form.Item>
    );
  },
};

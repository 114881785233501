import { Button, Form, Modal } from 'antd';
import { useRestaurant } from '../../store';
import { FormField } from '../../components/Forms/FormField';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { nanoid } from 'nanoid';
import { clone } from '../../utils';
import { t } from '../../components/LangProvider';
import { DeleteButton } from '../../components';

export const OrderLineForm = () => {
  const setRestaurant = useRestaurant((s) => s.set);
  const plates = useRestaurant((s) => s.plate);
  const order = useRestaurant((s) => s.order);
  const tempOrderLine = useRestaurant((s) => s.tempOrderLine);
  const [form] = Form.useForm();

  const plate = plates.find((x) => x._id === tempOrderLine?.plate);

  const handleCancel = () => {
    setRestaurant({ tempOrderLine: null });
  };

  const handleAdd = () => {
    const quantity = form.getFieldValue('quantity');
    form.setFieldValue('quantity', quantity + 1);
  };

  const handleRemove = () => {
    const quantity = form.getFieldValue('quantity');
    form.setFieldValue('quantity', quantity - 1);
  };

  const updateLine = (data) => {
    const newTempOrder = { ...order, isModified: true };
    const idField = data._id ? '_id' : '_temp';
    const lineId = data[idField];
    const index = order.lines.findIndex((line) => line[idField] === lineId);
    if (index < 0) {
      order.lines.push({ _temp: nanoid, ...data });
    } else {
      const newLine = { ...order.lines[index], ...data };
      order.lines[index] = newLine;
    }

    setRestaurant({ order: newTempOrder, tempOrderLine: null });
  };

  useEffect(() => {
    form.resetFields();
  });

  return (
    <Modal
      zIndex={1001}
      title={t('Editar')}
      onCancel={handleCancel}
      open={tempOrderLine != null}
      onOk={form.submit}
      forceRender
    >
      <Form form={form} layout="vertical" initialValues={tempOrderLine} onFinish={updateLine}>
        <FormField.Text name="_id" hidden={true} />
        <FormField.Text name="_temp" hidden={true} />
        <FormField.Text name="plate" hidden={true} />
        <FormField.Text name={['platePrice', 'amount']} hidden={true} />
        <FormField.Text name="type" hidden={true} />
        <div style={{ display: 'flex', gap: 8, alignItems: 'end' }}>
          <FormField.Number name="quantity" label="Cantidad" />
          <Button icon={<PlusCircleOutlined />} style={{ marginBottom: 8 }} onClick={handleAdd} />
          <Button icon={<MinusCircleOutlined />} style={{ marginBottom: 8 }} onClick={handleRemove} />
        </div>
        {plate?.addons.length ? (
          <FormField.CheckboxGroup
            name={'addons'}
            label="Complementos"
            dataSource={(plate?.addons ?? []).map((x) => ({ value: x._id, label: x.name }))}
          />
        ) : null}
        <FormField.TextArea name="observations" label="Observaciones" />
      </Form>
      <DeleteButton
        onConfirm={() => {
          const idField = tempOrderLine._id ? '_id' : '_temp';
          const orderLineId = tempOrderLine[idField];
          order.lines = order.lines.filter((x) => x[idField] !== orderLineId);
          setRestaurant({ order: clone(order), tempOrderLine: null });
        }}
      />
    </Modal>
  );
};

import { Result } from 'antd';
import { getCookie } from '../utils/cookies';
import { Navigate } from 'react-router';
import { t } from '../components/LangProvider';

export const NotFound = () => {
  const table = getCookie('table');

  if (table) {
    return <Navigate to={`/load/${table}`} replace={true} />;
  }

  return <Result status={'404'} title="Hmmm..." subTitle={t('Debes escanear el código QR')} />;
};

import { notification } from 'antd';
import axios from 'axios';

axios.interceptors.response.use(
  (response) => {
    if (response.data.errors) throw new Error(response.data.errors);
    return response;
  },
  (error) => {
    const { errors } = error.response.data;
    if (errors) {
      console.error(errors);
      const errorsList = Object.values(errors);

      errorsList.forEach((x) => {
        notification.error({ message: <strong>ERROR</strong>, description: x });
      });
    }
    return Promise.reject(error);
  }
);
export const isEmpty = (obj) => {
  if (obj === undefined) return true;

  if (
    typeof obj == 'function' ||
    typeof obj == 'number' ||
    typeof obj == 'boolean' ||
    Object.prototype.toString.call(obj) === '[object Date]'
  )
    return false;

  if (obj == null || obj.length === 0)
    // null or 0 length array
    return true;

  if (typeof obj == 'object') {
    // empty object

    var r = true;

    // eslint-disable-next-line no-unused-vars
    for (var f in obj) r = false;

    return r;
  }

  return false;
};

export const keyBy = (data = [], key = '_id') => {
  const result = {};
  if (Array.isArray(data)) {
    data.forEach((x) => {
      result[x[key]] = x;
    });
  } else {
    result[data[key]] = data;
  }

  return result;
};

export const clone = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export function sortByOrderAnd(dataField) {
  return (a, b) => {
    const aOrder = a.order ?? -1;
    const bOrder = b.order ?? -1;
    if (aOrder === bOrder) {
      if (a[dataField] < b[dataField]) {
        return -1;
      }

      if (a[dataField] > b[dataField]) {
        return 1;
      }

      return 0;
    }

    return aOrder - bOrder;
  };
}

import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export const BackButton = () => {
  return (
    <Link to={'..'} relative="path">
      <LeftOutlined style={{ color: 'white' }} />
    </Link>
  );
};

import { Button, Drawer, List } from 'antd';
import { useApp, useHotel, useRestaurant } from '../../store';
import { EditOutlined, PlusCircleOutlined, SendOutlined } from '@ant-design/icons';
import { clone } from '../../utils';
import { Actions } from '../../actions';
import { t } from '../LangProvider';

export const OrderDrawer = () => {
  const establishment = useApp((s) => s.establishment);
  const tpv = useApp((s) => s.tpv);
  const lang = sessionStorage.getItem('app-lang');

  const setRestaurant = useRestaurant((s) => s.set);
  const seeOrder = useRestaurant((s) => s.seeOrder);
  const plates = useRestaurant((s) => s.plate);
  const order = useRestaurant((s) => s.order);
  const table = useRestaurant((s) => s.table);
  const newOrderLines = useRestaurant((s) => s.newOrderLines);

  const reservation = useHotel((s) => s.reservation);

  const sendOrder = () => {
    let body;

    if (order?._id) {
      body = {
        lines: [...order.lines, ...newOrderLines],
      };

      if (establishment.type === 'hotel' && reservation) {
        body.hotelReservation = reservation._id;
      }

      Actions.put(`/order/table/${order._id}`, body).then((order) => {
        if (!order) return;
        setRestaurant({ order, seeOrder: false, newOrderLines: [] });
      });
      return;
    }

    body = {
      table: table._id,
      lines: newOrderLines,
    };

    if (establishment.type === 'hotel' && reservation) {
      body.hotelReservation = reservation._id;
    }

    Actions.post('/order/table', { table: table._id, lines: newOrderLines }).then((order) => {
      if (!order) return;
      setRestaurant({ order, seeOrder: false, newOrderLines: [] });
    });
  };

  const lineRender = (edit) => (line) => {
    const plate = plates.find((x) => x._id === line.plate);
    const addons = plate?.addons.filter((addon) => line.addons?.includes(addon._id)) ?? [];

    const addonsCaption = addons.map((x) => x.name).join(', ');

    const handleEdit = () => {
      setRestaurant({ newOrderLine: clone(line) });
    };

    const translation = (plate?.translations ?? []).find((x) => x.lang === lang)?.translation || plate?.name;

    return (
      <List.Item>
        <List.Item.Meta
          title={
            <div style={{ display: 'flex', gap: 8, alignItems: 'center', overflow: 'hidden', wordBreak: 'break-word' }}>
              <div>
                {line.quantity}x {translation}
              </div>
              {edit ? (
                <Button
                  style={{ marginLeft: 'auto' }}
                  icon={<EditOutlined />}
                  type="text"
                  size="small"
                  onClick={handleEdit}
                />
              ) : null}
              <Button
                icon={<PlusCircleOutlined />}
                style={{ marginLeft: edit ? undefined : 'auto' }}
                size="small"
                onClick={() => {
                  const lineIndex = newOrderLines.findIndex((x) => x._temp === line._temp);
                  newOrderLines[lineIndex].quantity += 1;

                  setRestaurant({ newOrderLines: [...newOrderLines] });
                }}
              />
            </div>
          }
          description={
            <div
              style={{
                display: 'flex',
                gap: 8,
                alignItems: 'end',
                overflow: 'hidden',
                wordBreak: 'break-word',
              }}
            >
              <div>
                <div>{addonsCaption}</div>
                <i>{line.observations}</i>
              </div>
            </div>
          }
        />
      </List.Item>
    );
  };

  return (
    <Drawer title={t('Seleccionado')} open={seeOrder} onClose={() => setRestaurant({ seeOrder: false })}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <List style={{ flexGrow: 1 }} dataSource={newOrderLines} renderItem={lineRender(true)} />
        {tpv.clientApp?.canDoOrder ? (
          <div style={{ marginTop: 8 }}>
            <Button type="primary" style={{ width: '100%' }} icon={<SendOutlined />} onClick={sendOrder}>
              {t('Pedir')}
            </Button>
          </div>
        ) : null}
      </div>
    </Drawer>
  );
};

import { lazy } from 'react';

export const AppRoutes = {
  '/': {
    title: 'Inicio',
    Component: lazy(() => import('../pages/Home')),
  },
  '/menu': {
    title: 'Menú',
    Component: lazy(() => import('../pages/Menu')),
  },
  '/menu/:itemType': {
    title: 'Menú',
    Component: lazy(() => import('../pages/Menu')),
  },
  '/order': {
    title: 'Comanda',
    Component: lazy(() => import('../pages/Order')),
  },
  '/ticket': {
    title: 'Ticket',
    Component: lazy(() => import('../pages/Ticket')),
  },
  '/:table': {
    title: 'Cargando...',
    Component: () => 'Cargando...',
  },
};

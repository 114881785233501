import { Spin, Typography } from 'antd';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApp, useRestaurant } from '../store';
import { Actions } from '../actions';
import { setCookie } from '../utils/cookies';
import { t } from '../components/LangProvider';

export const DataLoader = () => {
  const set = useApp((s) => s.set);
  const setRestaurant = useRestaurant((s) => s.set);
  const { table } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    Actions.get(`/table/${table}`).then(async (table) => {
      if (!table) {
        navigate('/', { replace: true });
        return;
      }
      const tableId = table._id;
      const establishmentId = table.establishment;
      const zone = await Actions.get(`/zone/${table.zone}`);

      Promise.all([
        Actions.get(`/establishment/${establishmentId}`),
        Actions.get(`/plate/establishment/${establishmentId}`, { tpv: zone.tpv }),
        Actions.get(`/itemType/establishment/${establishmentId}`, { tpv: zone.tpv }),
        Actions.get(`/order/table/${tableId}`),
        Actions.get(`/appExtension/establishment/${establishmentId}`),
      ])
        .then(async ([establishment, plate, itemType, order, extensions]) => {
          const tpv = await Actions.get(`/tpv/${zone.tpv}`);

          set({ establishment, tpv, dataLoaded: true, extensions });
          setRestaurant({ table, plate, itemType, zone, order });

          setCookie('table', tableId, 2);
          setCookie('establishment', establishmentId, 2);

          navigate('/', { replace: true });
        })
        .catch(() => null);
    });
  }, [table, setRestaurant, set, navigate]);

  return (
    <LoadingWrapper>
      <Typography.Title>{t('Cargando...')}</Typography.Title>
      <Spin spinning={true} size="large" />
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

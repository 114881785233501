import { Suspense, lazy, useState } from 'react';
import axios from 'axios';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MainLayout } from './components/layout';
import { SocketProvider } from './components/SocketProvider';
import { API_URL } from './constants/api';
import { AppRoutes } from './constants/routes';
import { useApp } from './store';
import { DataLoader } from './pages/DataLoader';
import { OrderDrawer } from './components/layout/OrderDrawer';
import { AllergensDrawer } from './components/layout/AllergensDrawer';
import { NotFound } from './pages/NotFound';
import { getCookie } from './utils/cookies';
import { ConfigProvider } from 'antd';
import { LangContext } from './components/LangProvider';

axios.defaults.baseURL = API_URL;

export const App = () => {
  const tpvType = useApp((s) => s.tpv?.type);
  const dataLoaded = useApp((s) => s.dataLoaded);
  const [lang, setLang] = useState(sessionStorage.getItem('app-lang') ?? 'ES');

  return (
    <LangContext.Provider value={{ lang, setLang }}>
      <ConfigProvider theme={{ token: { colorPrimary: '#c27' } }}>
        <SocketProvider>
          <MainLayout>
            <Suspense fallback={<>Cargando...</>}>
              <Routes>
                <Route path="/ticket/error" Component={lazy(() => import('./pages/TicketError'))} />
                <Route path="/ticket/success" Component={lazy(() => import('./pages/TicketSuccess'))} />
                {dataLoaded ? (
                  <>
                    {Object.entries(AppRoutes).map(([path, { Component }]) => {
                      return <Route key={path} path={path} Component={Component} />;
                    })}
                    <Route path="/*" element={<Navigate to="/" replace={true} />} />
                  </>
                ) : (
                  <>
                    <Route path="/reservation/:tpv" Component={lazy(() => import('./pages/Reservation'))} />
                    <Route path="/load/:table" Component={DataLoader} />
                    <Route path="/*" Component={NotFound} />
                  </>
                )}
              </Routes>
            </Suspense>
          </MainLayout>
          {tpvType === 'RESTAURANT' ? (
            <>
              <OrderDrawer />
              <AllergensDrawer />
            </>
          ) : null}
        </SocketProvider>
      </ConfigProvider>
    </LangContext.Provider>
  );
};

axios.defaults.headers = {
  Authorization: `Bearer ${getCookie('token')}`,
};

import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';
import { useRestaurant } from '../../store';
import { t } from '../LangProvider';

export const SeeOrderTooltip = ({ selectedQuantity }) => {
  const set = useRestaurant((s) => s.set);

  return (
    <Footer>
      <div>
        {selectedQuantity} {t('seleccionados')}
      </div>
      <Button type="primary" icon={<EyeOutlined />} onClick={() => set({ seeOrder: true })}>
        {t('Revisar')}
      </Button>
    </Footer>
  );
};

const Footer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-top: 1px solid #ccc;
`;

import { Button, Popconfirm } from 'antd';
import { t } from './LangProvider';
import { DeleteFilled } from '@ant-design/icons';
import styled from 'styled-components';

export const DeleteButton = ({ text = 'Eliminar', onConfirm }) => {
  return (
    <Popconfirm title={t('Confirmar')} onConfirm={onConfirm}>
      <Button type="dashed" danger icon={<DeleteFilled />} style={{ width: '100%' }}>
        {t(text).toUpperCase()}
      </Button>
    </Popconfirm>
  );
};

export const Toolbar = styled.div`
  display: flex;
  gap: 8px;
  padding: 4px 8px;
  align-items: center;
`;

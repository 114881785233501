import { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { API_URL } from '../constants/api';
import { useApp, useRestaurant } from '../store';
import { notification } from 'antd';
import { t } from './LangProvider';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState();
  const tpv = useApp((s) => s.tpv);
  const establishment = useApp((s) => s.establishment);
  const table = useRestaurant((s) => s.table);
  const setRestaurant = useRestaurant((s) => s.set);

  useEffect(() => {
    if (table && !socket) {
      // Conectamos al establecimiento
      const url = `${API_URL}/${establishment._id}`;
      const socket = io(url);

      setSocket(socket);

      // Registramos el cliente en la mesa correspondiente
      if (table) {
        socket.emit('register-guest', { table: table._id });
      }
    }
  }, [table, establishment, socket, tpv]);

  useEffect(() => {
    if (!socket) return;

    socket.addEventListener('order', (order) => setRestaurant({ order }));
    socket.addEventListener('ticket', (ticket) => {
      setRestaurant({ ticket });
    });
    socket.addEventListener('delete-order', () => setRestaurant({ order: null }));

    socket.addEventListener('accept-request', () => notification.success({ message: t('Camarero en camino') }));
    socket.addEventListener('refuse-request', () =>
      notification.warning({ message: t('Ahora mismo no pueden atenderle') })
    );

    return () => {
      if (!socket) return;
      socket.removeAllListeners();
    };
  }, [socket, setRestaurant]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

import { BellOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useSocket } from '../SocketProvider';
import { useApp, useRestaurant } from '../../store';
import { useEffect, useState } from 'react';
import { t } from '../LangProvider';

export const CallWaiterButton = () => {
  const [time, setTime] = useState(0);
  const socket = useSocket();

  const tpv = useApp((s) => s.tpv);
  const zone = useRestaurant((s) => s.zone);
  const table = useRestaurant((s) => s.table);

  const handleCallWaiter = () => {
    setTime(30);
    socket.emit('call-waiter', { tpv: tpv._id, table: table._id });
  };

  useEffect(() => {
    let timer;
    if (time > 0) {
      timer = setTimeout(() => {
        setTime((prev) => prev - 1);
      }, 1 * 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [time]);

  const canCallWaiter = tpv.clientApp?.canCallWaiter && zone.clientApp?.canCallWaiter;
  if (!canCallWaiter) {
    return null;
  }

  return (
    <Button disabled={time > 0} icon={<BellOutlined />} onClick={handleCallWaiter}>
      {time ? time : t('Camarero')}
    </Button>
  );
};

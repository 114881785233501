import { keyBy } from '../utils';
import { create } from 'zustand';
import { getCookie } from '../utils/cookies';

export const useApp = create((set) => ({
  loading: false,
  dataLoaded: false,
  establishment: null,
  establishmentInfo: null,
  tpv: null,
  currentForm: { id: null, data: {} },
  set,
}));

export const useHotel = create((set) => ({
  reservation: JSON.parse(getCookie('reservation')),
  set,
}));

export const useRestaurant = create((set, get) => ({
  seeOrder: false,
  seeAllergens: false,
  newOrderLines: [],
  tempOrderLine: null,
  allergens: [],
  table: null,
  zone: null,
  order: null,
  ticket: null,
  plate: [],
  itemType: [],
  selectedService: null,
  set,
  upsert(key, data) {
    const joinedData = { ...keyBy(get()[key]), ...keyBy(data) };
    set({ [key]: Object.values(joinedData) });
  },
  delete(key, ids) {
    set({ [key]: get()[key].filter((x) => !ids.includes(x._id)) });
  },
}));

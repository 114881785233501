import { Layout, Typography } from 'antd';
import styled from 'styled-components';
import { useApp, useRestaurant } from '../../store';
import { OrderLineForm } from '../../pages/Menu/OrderLineForm';
import { NewOrderLineForm } from '../../pages/Menu/NewOrderLineForm';
import { useLocation } from 'react-router';
import { CallWaiterButton } from './CallWaiterButton';
import { BackButton } from './BackButton';
import { SeeOrderTooltip } from './SeeOrderTooltip';
import { LangSelector, t } from '../LangProvider';

const { Header } = Layout;

export const MainLayout = ({ children }) => {
  const { pathname } = useLocation();
  const dataLoaded = useApp((s) => s.dataLoaded);
  const establishment = useApp((s) => s.establishment);
  const tpv = useApp((s) => s.tpv);

  const itemTypes = useRestaurant((s) => s.itemType);
  const newOrderLines = useRestaurant((s) => s.newOrderLines);

  const itemType = pathname.startsWith('/menu/') ? pathname.split('/menu/')[1] : null;

  const selectedQuantity = getSelectedQuantity(newOrderLines);

  const title =
    (itemTypes ?? [])
      .concat({ name: t('Ofertas'), _id: 'offers' }, { name: t('Sugerencias'), _id: 'suggested' })
      .find((x) => x._id === itemType)?.name ??
    establishment?.name ??
    'Virtual Waiter';

  return (
    <Layout style={{ height: '100%' }}>
      <Header style={{ alignItems: 'center', display: 'flex', gap: 8, padding: '0 24px', background: '#c27' }}>
        {dataLoaded && pathname !== '/' ? <BackButton /> : null}
        <Typography.Title style={{ margin: 0, color: 'white', fontSize: '24px' }}>{title}</Typography.Title>
        <LangSelector />
        {tpv?.clientApp?.canCallWaiter ? <CallWaiterButton /> : null}
      </Header>
      <AppContent>{children}</AppContent>
      {dataLoaded && selectedQuantity > 0 ? <SeeOrderTooltip selectedQuantity={selectedQuantity} /> : null}
      <OrderLineForm />
      <NewOrderLineForm />
    </Layout>
  );
};

const getSelectedQuantity = (newOrderLines) => {
  let plateQuantity = 0;

  for (const { quantity = 1, servedQuantity = 0 } of newOrderLines ?? []) {
    plateQuantity += quantity - servedQuantity;
  }

  return plateQuantity;
};

const AppContent = styled.main`
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden auto;
`;

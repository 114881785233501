import { Checkbox, Drawer } from 'antd';
import { useRestaurant } from '../../store';
import { AllergenDetail } from '../../constants/allergens';
import { t } from '../LangProvider';

export const AllergensDrawer = () => {
  const setRestaurant = useRestaurant((s) => s.set);
  const seeAllergens = useRestaurant((s) => s.seeAllergens);
  const selectedAllergens = useRestaurant((s) => s.allergens);

  const handleClose = () => setRestaurant({ seeAllergens: false });

  return (
    <Drawer title={t('Alérgenos')} open={seeAllergens} onClose={handleClose}>
      <p>{t('Marca los alérgenos necesarios para no mostrarte los platos que los contengan')}</p>
      <Checkbox.Group
        style={{ display: 'flex', flexDirection: 'column' }}
        onChange={(allergens) => setRestaurant({ allergens })}
        value={selectedAllergens}
        options={Object.entries(AllergenDetail).map(([value, { name: label }]) => ({
          value: Number(value),
          label: t(label),
        }))}
      />
    </Drawer>
  );
};

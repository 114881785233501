export const AllergenDetail = {
  0: {
    name: 'Gluten',
    image: '/assets/allergens/Gluten.png',
  },
  1: {
    name: 'Crustáceos',
    image: '/assets/allergens/Crustaceos.png',
  },
  2: {
    name: 'Huevos',
    image: '/assets/allergens/Huevo.png',
  },
  3: {
    name: 'Pescado',
    image: '/assets/allergens/Pescado.png',
  },
  4: {
    name: 'Cacahuetes',
    image: '/assets/allergens/Cacahuetes.png',
  },
  5: {
    name: 'Soja',
    image: '/assets/allergens/Soja.png',
  },
  6: {
    name: 'Lácteos',
    image: '/assets/allergens/Lacteos.png',
  },
  7: {
    name: 'Frutos de cascara',
    image: '/assets/allergens/Frutos_de_cascara.png',
  },
  8: {
    name: 'Apio',
    image: '/assets/allergens/Apio.png',
  },
  9: {
    name: 'Mostaza',
    image: '/assets/allergens/Mostaza.png',
  },
  10: {
    name: 'Granos de sésamo',
    image: '/assets/allergens/Sesamo.png',
  },
  11: {
    name: 'Dióxido de azufre y sulfitos',
    image: '/assets/allergens/Dioxido_de_azufre_y_sulfitos.png',
  },
  12: {
    name: 'Moluscos',
    image: '/assets/allergens/Moluscos.png',
  },
  13: {
    name: 'Altramuces',
    image: '/assets/allergens/Altramuces.png',
  },
};

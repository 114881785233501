// export const API_URL = 'http://localhost:3005';
export const API_URL = 'https://vw-api.kuarasoftware.es';

export const TWO_HOURS = 0.0833;

export const ORDER_LINE_STATES = {
  SELECTED: 0,
  PENDING: 1,
  COOKING: 2,
  FINISHED: 3,
  SERVED: 4,
  CHARGED: 5,
};

export const ORDER_LINE_STATES_LIST = Object.values(ORDER_LINE_STATES);

// Ticket types
export const TICKET_TYPES = {
  PREVIOUS: 'PREVIOUS',
  FINAL: 'FINAL',
};

// Langs
export const LANGS = {
  ES: {
    value: 'ES',
    label: 'Español',
  },
  EN: {
    value: 'EN',
    label: 'Inglés',
  },
  DE: {
    value: 'DE',
    label: 'Alemán',
  },
  FR: {
    value: 'FR',
    label: 'Francés',
  },
  IT: {
    value: 'IT',
    label: 'Italiano',
  },
};

export const LANGS_LIST = Object.values(LANGS);

// Payment methods
export const PAYMENT_METHODS = {
  CREDIT_CARD: {
    value: 'CREDIT_CARD',
    label: 'Tarjeta',
    show: true,
  },
};

export const PAYMENT_METHODS_LIST = Object.values(PAYMENT_METHODS);

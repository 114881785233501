import { Button, Form, Modal } from 'antd';
import { useRestaurant } from '../../store';
import { FormField } from '../../components/Forms/FormField';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { nanoid } from 'nanoid';
import { t } from '../../components/LangProvider';
import { DeleteButton } from '../../components';

export const NewOrderLineForm = () => {
  const setRestaurant = useRestaurant((s) => s.set);
  const plates = useRestaurant((s) => s.plate);
  const newOrderLines = useRestaurant((s) => s.newOrderLines);
  const newOrderLine = useRestaurant((s) => s.newOrderLine);
  const [form] = Form.useForm();

  const plate = plates.find((x) => x._id === newOrderLine?.plate);

  const handleCancel = () => {
    setRestaurant({ newOrderLine: null });
  };

  const handleAdd = () => {
    const quantity = form.getFieldValue('quantity');
    form.setFieldValue('quantity', quantity + 1);
  };

  const handleRemove = () => {
    const quantity = form.getFieldValue('quantity');
    form.setFieldValue('quantity', quantity - 1);
  };

  const updateLine = (data) => {
    const lineId = data._temp;
    const index = newOrderLines.findIndex((line) => line._temp === lineId);
    if (index < 0) {
      newOrderLines.push({ _temp: nanoid, ...data });
    } else {
      const newLine = { ...newOrderLines[index], ...data };
      newOrderLines[index] = newLine;
    }

    setRestaurant({ newOrderLines: [...newOrderLines], newOrderLine: null });
  };

  useEffect(() => {
    form.resetFields();
  });

  return (
    <Modal
      zIndex={1001}
      title={t('Editar')}
      onCancel={handleCancel}
      open={newOrderLine != null}
      onOk={form.submit}
      forceRender
    >
      <Form form={form} layout="vertical" initialValues={newOrderLine} onFinish={updateLine}>
        <FormField.Text name="_id" hidden={true} />
        <FormField.Text name="_temp" hidden={true} />
        <FormField.Text name="plate" hidden={true} />
        <FormField.Text name="itemType" hidden={true} />
        <FormField.Text name={['platePrice', 'amount']} hidden={true} />
        <FormField.Select
          name="type"
          label="Tipo"
          required={true}
          dataSource={[
            { value: 'STARTERS', label: 'Entrante' },
            { value: 'FIRST', label: 'Primero' },
            { value: 'SECONDS', label: 'Segundo' },
            { value: 'DESSERTS', label: 'Postre' },
          ]}
        />
        <div style={{ display: 'flex', gap: 8, alignItems: 'end' }}>
          <FormField.Number name="quantity" label="Cantidad" />
          <Button icon={<PlusCircleOutlined />} style={{ marginBottom: 8 }} onClick={handleAdd} />
          <Button icon={<MinusCircleOutlined />} style={{ marginBottom: 8 }} onClick={handleRemove} />
        </div>
        {plate?.addons.length ? (
          <FormField.CheckboxGroup
            name={'addons'}
            label="Complementos"
            dataSource={(plate?.addons ?? []).map((x) => ({ value: x._id, label: x.name }))}
          />
        ) : null}
        <FormField.TextArea name="observations" label="Observaciones" />
      </Form>
      <DeleteButton
        onConfirm={() => {
          const orderLineId = newOrderLine._temp;
          const filtered = newOrderLines.filter((x) => x._temp !== orderLineId);
          setRestaurant({ newOrderLines: filtered, newOrderLine: null });
        }}
      />
    </Modal>
  );
};

import { Select } from 'antd';
import GB from '../lang/GB.json';
import { createContext, useContext } from 'react';
import { useApp } from '../store';

const captions = { GB };

export const t = (msg) => {
  const lang = sessionStorage.getItem('app-lang') ?? 'ES';

  const caption = captions[lang]?.[msg];

  if (caption) {
    return caption;
  }

  return msg;
};

export const LangContext = createContext({ lang: 'ES' });

export const LangSelector = () => {
  const { lang, setLang } = useContext(LangContext);
  const { availableLangs = [] } = useApp((s) => s.tpv?.clientApp ?? {});

  return (
    <Select
      style={{ marginLeft: 'auto', display: 'block', textAlign: 'center' }}
      value={lang}
      onChange={(lang) => {
        setLang(lang);
        sessionStorage.setItem('app-lang', lang);
      }}
      options={Object.entries(langs)
        .filter(([value]) => availableLangs.includes(value))
        .concat([['ES', 'Español']])
        .map(([value, label]) => {
          return {
            label: (
              <img
                alt={t(label)}
                height={'100%'}
                width={16}
                src={`https://flagicons.lipis.dev/flags/4x3/${value.toLowerCase()}.svg`}
              />
            ),
            value,
          };
        })}
    />
  );
};

const langs = {
  ES: 'Español',
  GB: 'English',
  IT: 'Italiano',
  FR: 'Français',
  DE: 'Deutsch',
};
